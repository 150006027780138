const actions = {
  GET_TRANSACTIONS_DATA: "GET_TRANSACTIONS_DATA",
  GET_ALL_TRANSACTIONS_DATA: "GET_ALL_TRANSACTIONS_DATA",
  GET_TRANSACTIONS_DATA_SUCCESS: "GET_TRANSACTIONS_DATA_SUCCESS",
  GET_ALL_TRANSACTIONS_DATA_SUCCESS: "GET_ALL_TRANSACTIONS_DATA_SUCCESS",
  GET_TRANSACTIONS_DATA_FAILED: "GET_TRANSACTIONS_DATA_FAILED",
  GET_ALL_TRANSACTIONS_DATA_FAILED: "GET_ALL_TRANSACTIONS_DATA_FAILED",
  RESET_ALL_TRANSACTIONS_DATA: "RESET_ALL_TRANSACTIONS_DATA",
  GET_CHART_DATA: "GET_CHART_DATA",
  GET_CHART_DATA_SUCCESS: "GET_CHART_DATA_SUCCESS",
  GET_CHART_DATA_FAILED: "GET_CHART_DATA_FAILED",
  GET_DAILY_CHART_DATA: "GET_DAILY_CHART_DATA",
  GET_DAILY_CHART_DATA_SUCCESS: "GET_DAILY_CHART_DATA_SUCCESS",
  GET_DAILY_CHART_DATA_FAILED: "GET_DAILY_CHART_DATA_FAILED",
  GET_DAILY_CHART_DATA_RESET: "GET_DAILY_CHART_DATA_RESET",
  GET_PIE_CHART_DATA: "GET_PIE_CHART_DATA",
  GET_PIE_CHART_DATA_SUCCESS: "GET_PIE_CHART_DATA_SUCCESS",
  GET_PIE_CHART_DATA_FAILED: "GET_PIE_CHART_DATA_FAILED",
  GET_PIE_CHART_DATA_RESET: "GET_PIE_CHART_DATA_RESET",
  GET_FULL_TRANSACTIONS_DETAILS: "GET_FULL_TRANSACTIONS_DETAILS",
  GET_FULL_TRANSACTIONS_DETAILS_SUCCESS:
    "GET_FULL_TRANSACTIONS_DETAILS_SUCCESS",
  GET_FULL_TRANSACTIONS_DETAILS_ERROR: "GET_FULL_TRANSACTIONS_DETAILS_ERROR",
  GET_FULL_TRANSACTIONS_DETAILS_RESET: "GET_FULL_TRANSACTIONS_DETAILS_RESET",

  getTransactionsData: (page, limit, search, fromDate, toDate, billerId, transactionStatusFilter) => ({
    type: actions.GET_TRANSACTIONS_DATA,
    payload: { page, limit, search, fromDate, toDate, billerId, transactionStatusFilter },
  }),
  getTransactionsDataSuccess: (data, totalData) => ({
    type: actions.GET_TRANSACTIONS_DATA_SUCCESS,
    payload: { data, totalData },
  }),
  getTransactionsDataFailed: (error) => ({
    type: actions.GET_TRANSACTIONS_DATA_FAILED,
    payload: { error },
  }),

  getAllTransactionsData: (
    offset,
    limit,
    search,
    fromDate,
    toDate,
    agentId
  ) => ({
    type: actions.GET_ALL_TRANSACTIONS_DATA,
    payload: { offset, limit, search, fromDate, toDate, agentId },
  }),
  getAllTransactionsDataSuccess: (data, totalData) => ({
    type: actions.GET_ALL_TRANSACTIONS_DATA_SUCCESS,
    payload: { data, totalData },
  }),
  getAllTransactionsDataFailed: (error) => ({
    type: actions.GET_ALL_TRANSACTIONS_DATA_FAILED,
    payload: { error },
  }),
  resetAllTransactionsData: () => ({
    type: actions.RESET_ALL_TRANSACTIONS_DATA,
  }),

  getChartData: (agentId) => ({
    type: actions.GET_CHART_DATA,
    payload: { agentId },
  }),
  getChartDataSuccess: (data) => ({
    type: actions.GET_CHART_DATA_SUCCESS,
    payload: { data },
  }),
  getChartDataFailed: (error) => ({
    type: actions.GET_CHART_DATA_FAILED,
    payload: { error },
  }),
  getDailyChartData: (billerId) => ({
    type: actions.GET_DAILY_CHART_DATA,
    payload: { billerId },
  }),
  getDailyChartDataSuccess: (data) => ({
    type: actions.GET_DAILY_CHART_DATA_SUCCESS,
    payload: { data },
  }),
  getDailyChartDataFailed: (error) => ({
    type: actions.GET_DAILY_CHART_DATA_FAILED,
    payload: { error },
  }),
  getDailyChartDataReset: () => ({
    type: actions.GET_DAILY_CHART_DATA_RESET,
  }),
  getPieChartData: (billerId) => ({
    type: actions.GET_PIE_CHART_DATA,
    payload: { billerId },
  }),
  getPieChartDataSuccess: (data) => ({
    type: actions.GET_PIE_CHART_DATA_SUCCESS,
    payload: { data },
  }),
  getPieChartDataFailed: (error) => ({
    type: actions.GET_PIE_CHART_DATA_FAILED,
    payload: { error },
  }),
  getPieChartDataReset: () => ({
    type: actions.GET_PIE_CHART_DATA_RESET,
  }),

  getFullTransactionDetails: (refId) => ({
    type: actions.GET_FULL_TRANSACTIONS_DETAILS,
    payload: { refId },
  }),
  getFullTransactionsDetailsSuccess: (data) => ({
    type: actions.GET_FULL_TRANSACTIONS_DETAILS_SUCCESS,
    payload: { data },
  }),
  getFullTransactionDetailsError: (error) => ({
    type: actions.GET_FULL_TRANSACTIONS_DETAILS_ERROR,
    payload: { error },
  }),
  getFullTransactionsDetailsReset: () => ({
    type: actions.GET_FULL_TRANSACTIONS_DETAILS_RESET,
  }),
};

export default actions;

const actions = {
  FETCH_FILE: "FETCH_FILE",
  FETCH_DATA_SUCCESS: "FETCH_DATA_SUCCESS",
  FETCH_DATA_FAILURE: "FETCH_DATA_FAILURE",
  FETCH_DATA_RESET: "FETCH_DATA_RESET",
  FETCH_DATA_NOTIFIED: "FETCH_DATA_NOTIFIED",
  UPLOAD_FILE: "UPLOAD_FILE",
  UPLOAD_FILE_NEW_REPORT: "UPLOAD_FILE_NEW_REPORT",
  UPLOAD_DATA_SUCCESS: "UPLOAD_FILE_SUCCESS",
  UPLOAD_DATA_SUCCESS_NEW_REPORT: "UPLOAD_DATA_SUCCESS_NEW_REPORT",
  UPLOAD_DATA_FAILURE: "UPLOAD_FILE_FAILURE",
  UPLOAD_DATA_FAILURE_NEW_REPORT: "UPLOAD_DATA_FAILURE_NEW_REPORT",
  UPLOAD_DATA_RESET: "UPLOAD_DATA_RESET",
  UPLOAD_DATA_RESET_NEW_REPORT: "UPLOAD_DATA_RESET_NEW_REPORT",
  UPLOAD_DATA_NOTIFIED: "UPLOAD_DATA_NOTIFIED",
  UPLOAD_DATA_NOTIFIED_NEW_REPORT: "UPLOAD_DATA_NOTIFIED_NEW_REPORT",
  UPLOAD_XML_ZIP_FILE: "UPLOAD_XML_FILE",
  UPLOAD_XML_ZIP_FILE_SUCCESS: "UPLOAD_XML_ZIP_FILE_SUCCESS",
  UPLOAD_XML_ZIP_FILE_FAILED: "UPLOAD_XML_ZIP_FILE_FAILED",
  UPLOAD_XML_ZIP_FILE_NOTIFIED: "UPLOAD_XML_ZIP_FILE_NOTIFIED",
  UPLOAD_XML_ZIP_FILE_RESET: "UPLOAD_XML_ZIP_FILE_RESET",

  getReconData: (data) => ({
    type: actions.FETCH_FILE,
    payload: data,
  }),

  getReconDataSuccess: (data) => ({
    type: actions.FETCH_DATA_SUCCESS,
    payload: { data },
  }),

  getReconDataFailure: (error) => ({
    type: actions.FETCH_DATA_FAILURE,
    payload: { error },
  }),

  getReconDataReset: () => ({
    type: actions.FETCH_DATA_RESET,
  }),

  getReconDataNotified: () => ({
    type: actions.FETCH_DATA_NOTIFIED,
  }),

  UploadFile: (data) => ({
    type: actions.UPLOAD_FILE,
    payload: data,
  }),

  UploadFileNewReport: (data) => ({
    type: actions.UPLOAD_FILE_NEW_REPORT,
    payload: data,
  }),

  UploadFileSuccessNewReport: (data) => ({
    type: actions.UPLOAD_DATA_SUCCESS_NEW_REPORT,
    payload: { data },
  }),

  UploadFileSuccess: (data) => ({
    type: actions.UPLOAD_DATA_SUCCESS,
    payload: { data },
  }),

  UploadFileFailure: (error) => ({
    type: actions.UPLOAD_DATA_FAILURE,
    payload: { error },
  }),
  UploadFileFailureNewReport: (error) => ({
    type: actions.UPLOAD_DATA_FAILURE_NEW_REPORT,
    payload: { error },
  }),
  uploadDataReset: () => ({
    type: actions.UPLOAD_DATA_RESET,
  }),
  uploadDataResetNewReport: () => ({
    type: actions.UPLOAD_DATA_RESET_NEW_REPORT,
  }),
  uploadDataNotified: () => ({
    type: actions.UPLOAD_DATA_NOTIFIED,
  }),
  uploadDataNotifiedNewReport: () => ({
    type: actions.UPLOAD_DATA_NOTIFIED_NEW_REPORT,
  }),
  uploadXmlZipFile: (data) => ({
    type: actions.UPLOAD_XML_ZIP_FILE,
    payload: { data }
  }),
  uploadXmlZipFileSuccess: (data) => ({
    type: actions.UPLOAD_XML_ZIP_FILE_SUCCESS,
    payload: { data }
  }),
  uploadXmlZipFileFailed: (error) => ({
    type: actions.UPLOAD_XML_ZIP_FILE_FAILED,
    payload: { error }
  }),
  uploadXmlZipFileNotified: () => ({
    type: actions.UPLOAD_XML_ZIP_FILE_NOTIFIED,
  }),
  uploadXmlZipFileReset: () => ({
    type: actions.UPLOAD_XML_ZIP_FILE_RESET
  })
};

export default actions;

import { all, takeEvery, put, call } from "redux-saga/effects";
import actions from "./actions";
import { getDataFromBOU } from "../../Services/ApiMethodHelper";
import {
  getTransactionsData,
  getPiechartData,
  getDailyGraphData,
  getFullTransactionHistoryData,
} from "../../graphql/Queries";
import client from "../../graphql/ApolloClient";
const baseURLBOU = process.env.REACT_APP_API_BASE_URL;
const baseURLBOUExport = "https://dmrvr7r4-3000.inc1.devtunnels.ms/";

function* getTransactionsDataResponse({
  payload: {
    page,
    limit,
    search,
    fromDate,
    toDate,
    billerId,
    transactionStatusFilter,
  },
}) {
  try {
    const response = yield call(client.query, {
      query: getTransactionsData,
      variables: {
        queryBouDto: {
          ...(billerId && { billerId }),
          ...(page && { page }),
          ...(limit && { limit }),
          ...(search && { search }),
          ...(fromDate && { fromDate }),
          ...(toDate && { toDate }),
          ...(transactionStatusFilter && { status: transactionStatusFilter }),
        },
      },
    });

    const { error, data } = response;
    if (error) {
      yield put(actions.getTransactionsDataFailed(error));
    } else {
      yield put(actions.getTransactionsDataSuccess(data.getTransactionsBOU));
    }
  } catch (error) {
    yield put(actions.getTransactionsDataFailed(error));
  }
}

function* getChartDataResponse({ payload: { agentId } }) {
  try {
    const response = yield call(
      getDataFromBOU,
      `${baseURLBOU}bou/dashboard/graph?${agentId ? `billerId=${agentId}` : ""}`
    );
    yield put(actions.getChartDataSuccess(response.data));
  } catch (error) {
    yield put(actions.getChartDataFailed(error?.response?.data?.message));
  }
}

function* getDailyChartDataResponse({ payload: { billerId } }) {
  try {
    const response = yield call(client.query, {
      query: getDailyGraphData,
      variables: {
        billerId: billerId ? billerId : "",
      },
    });
    const { error, data } = response;
    if (error) {
      yield put(actions.getDailyChartDataFailed(error));
    } else {
      yield put(
        actions.getDailyChartDataSuccess(data.getGraphDataForDailyTransactions)
      );
    }
  } catch (error) {
    yield put(actions.getDailyChartDataFailed(error));
  }
}

function* getPieChartDataResponse({ payload: { billerId } }) {
  try {
    const response = yield call(client.query, {
      query: getPiechartData,
      variables: {
        billerId: billerId ? billerId : "",
      },
    });
    const { error, data } = response;
    if (error) {
      yield put(actions.getPieChartDataFailed(error));
    } else {
      yield put(actions.getPieChartDataSuccess(data?.getPieChartData));
    }
  } catch (error) {
    yield put(actions.getPieChartDataFailed(error));
  }
}

// function get all transaction logs / details
function* getFullTransactionDetails({ payload: { refId } }) {
  try {
    const response = yield call(client.query, {
      query: getFullTransactionHistoryData,
      variables: {
        refData: {
          ...(refId && { refId: refId }),
        },
      },
    });
    const { error, data } = response;
    if (error) {
      yield put(actions.getFullTransactionDetailsError(error));
    } else {
      yield put(
        actions.getFullTransactionsDetailsSuccess(data?.sendTransactionHistory)
      );
    }
  } catch (error) {
    yield put(actions.getFullTransactionDetailsError(error));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_TRANSACTIONS_DATA, getTransactionsDataResponse),
  ]);
  // yield all([takeEvery(actions.GET_ALL_TRANSACTIONS_DATA, getAllTransactionsDataResponse)]);
  yield all([takeEvery(actions.GET_CHART_DATA, getChartDataResponse)]);
  yield all([
    takeEvery(actions.GET_DAILY_CHART_DATA, getDailyChartDataResponse),
  ]);
  yield all([takeEvery(actions.GET_PIE_CHART_DATA, getPieChartDataResponse)]);
  yield all([
    takeEvery(actions.GET_FULL_TRANSACTIONS_DETAILS, getFullTransactionDetails),
  ]);
}

import actions from "./actions";
import { all, takeEvery, put, call } from "redux-saga/effects";
// import {fetchReconFile} from "../../Services/ApiMethodHelper";
import { getDataFromBOU, postData } from "../../Services/ApiMethodHelper";
const baseURL = "https://prod.recon.idfc.plutos.one/";
// const baseURL = "https://rp6pb2z5-7070.inc1.devtunnels.ms/";

function* getReconDataResponse({ payload: { cycleNo, date, fileType, billerId } }) {
  try {
    const response = yield call(
      getDataFromBOU,
      `${baseURL}file-fetch?cycleNo=${cycleNo}&date=${date}&fileType=${fileType}${billerId ? `&billerId=${billerId}` : ""}`
    );

    if (response.status === 201 || response.status === 200) {
      yield put(actions.getReconDataSuccess(response?.data));
    } else {
      yield put(actions.getReconDataFailure(response?.message));
    }
  } catch (error) {
    yield put(actions.getReconDataFailure(error?.response?.data || error));
  }
}

function* uploadReconData({ payload }) {
  const { file } = payload;
  console.log(file, "upload`saga")
  try {
    const response = yield call(postData, `${baseURL}file-upload`, file);

    if (response.status === 201 || response.status === 200) {
      yield put(actions.UploadFileSuccess(response?.data));
    } else {
      yield put(actions.UploadFileFailure(response?.message));
    }
  } catch (error) {
    yield put(actions.UploadFileFailure(error?.response?.data || error))
  }
}

// upload file by billerid for new reports
function* uploadReconDataNewReport({ payload }) {
  // console.log(payload,"new-report--->")
  // const newPayload = payload?.newForm ? payload.newForm : payload.file
  try {
    const response = yield call(postData, `${baseURL}file-upload/new-reports`, payload);

    if (response.status === 201 || response.status === 200) {
      yield put(actions.UploadFileSuccessNewReport(response?.data));
    } else {
      yield put(actions.UploadFileFailureNewReport(response?.message));
    }
  } catch (error) {
    yield put(actions.UploadFileFailureNewReport(error?.response?.data || error))
  }
}

function* uploadXMLasZip({payload:{data}}) {
  try {
    const response = yield call(postData, `${baseURL}aggregation/xml-upload`, data?.payload);
    console.log(response,"response")
    if (response.status===201) {
      yield put(actions.uploadXmlZipFileSuccess(response?.data))
    } else {
      yield put(actions.uploadXmlZipFileFailed(response?.message))
    }
  } catch (error) {
    yield put(actions.uploadXmlZipFileFailed(error?.response?.data || error))
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.FETCH_FILE, getReconDataResponse)]);
  yield all([takeEvery(actions.UPLOAD_FILE, uploadReconData)]);
  yield all([takeEvery(actions.UPLOAD_FILE_NEW_REPORT, uploadReconDataNewReport)]);
  yield all([takeEvery(actions.UPLOAD_XML_ZIP_FILE, uploadXMLasZip)]);
}

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DnD from "./Dnd";
import { message } from "antd";
import UFilter from "./UploadFilter";
import actions from "../../../redux/ReconAndSettlement/actions";

const Uploads = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    cycleNo: "",
    fileType: "",
    date: "",
    billerId:null
  });
  const [file, setFile] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();

  const { uploadWithoutBillerId, uploadByBillerId } = useSelector((state) => {
    return {
      uploadWithoutBillerId: state.reconReducer?.uploadReconData?.withoutBillerId,
      uploadByBillerId: state.reconReducer?.uploadReconData?.byBillerId,
    };
  });

  const form = new FormData();
    form.append("cycleNo", formData.cycleNo);
    form.append("fileType", formData.fileType);
    form.append("date", formData.date);
    form.append("file", file);

  const handleSubmit = () => {
    if (!file) {
      message.error("No file selected");
      return;
    }

    dispatch(
      actions.UploadFile({
        cycleNo: formData.cycleNo,
        date: formData.date,
        fileType: formData.fileType,
        file: form,
      })
    );
  };

  useEffect(() => {
    if (uploadWithoutBillerId?.loading === true && uploadWithoutBillerId?.error === null) {
      messageApi.destroy();
      messageApi.open({
        type: "loading",
        content: "uploading.....",
      });
    } else if (
      uploadWithoutBillerId?.loading === false &&
      uploadWithoutBillerId?.error !== null
    ) {
      messageApi.destroy();
      messageApi
        .open({
          type: "error",
          content: uploadWithoutBillerId?.error !== null && uploadWithoutBillerId?.error,
          duration: 1,
        })
        .then(() => dispatch(actions.uploadDataReset()));
    } else if (
      uploadWithoutBillerId?.loading === false &&
      uploadWithoutBillerId?.error === null &&
      uploadWithoutBillerId?.notified === false &&uploadWithoutBillerId?.response?.message?.length>0
    ) {
      messageApi.destroy();
      messageApi.open({
        type: "success",
        content: uploadWithoutBillerId?.response?.message,
        duration: 1,
      }).then(()=>dispatch(actions.uploadDataReset()));
    }
  }, [uploadWithoutBillerId]);

  // useEffect(()=>{
  //   if(uploadByBillerId?.response?.message?.length>0){
  //     setFormData({
  //       cycleNo: "",
  //       fileType: "",
  //       date: "",
  //     })
  //     setFile(null)
  //   }
  // },[uploadByBillerId?.response])

  return (
    <div>
      {contextHolder}
      <div className="filter-container">
        <UFilter
          handleSubmit={handleSubmit}
          setFormData={setFormData}
          formData={formData}
        />
        <DnD setFormDataState={setFile} />
      </div>
    </div>
  );
};

export default Uploads;

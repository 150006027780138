import { gql } from "@apollo/client";

export const getTransactionsData = gql`
  query GetTransactionsBOU($queryBouDto: TransactionBouQuery!) {
    getTransactionsBOU(queryBouDto: $queryBouDto) {
      transactions {
        # agentId
        amount
        # bankId
        billAmount
        # billerAck
        # billerFee
        billerId
        createdAt
        cuResponse
        # currency
        customerParams {
          tag {
            name
            value
          }
        }
        # email
        # isDeleted
        # mobile
        # msgId
        # name
        paymentMode
        refId
        # responseCode
        # splitPay
        status
        transactionId
        # txnDate
        # txnReferenceId
        # updatedAt
      }
      total
      totalAmount
    }
  }
`;

export const getPiechartData = gql`
  query GetPiecharData($billerId: String!) {
    getPieChartData(billerId: $billerId) {
      paymentModeStats {
        amountPercentage
        paymentMode
        totalAmount
        totalTransactions
        transactionPercentage
      }
      statusStats {
        percentage
        status
        totalTransactions
      }
    }
  }
`;

export const getDailyGraphData = gql`
  query GetDailyGraphData($billerId: String!) {
    getGraphDataForDailyTransactions(billerId: $billerId) {
      daily {
        time
        data {
          amount
          successTransactions
          failedTransactions
        }
      }
      oneWeek {
        time
        data {
          amount
          successTransactions
          failedTransactions
        }
      }
      oneMonth {
        time
        data {
          amount
          successTransactions
          failedTransactions
        }
      }
      threeMonths {
        time
        data {
          amount
          successTransactions
          failedTransactions
        }
      }
      sixMonths {
        time
        data {
          amount
          successTransactions
          failedTransactions
        }
      }
      oneYear {
        time
        data {
          amount
          successTransactions
          failedTransactions
        }
      }
      fiveYears {
        time
        data {
          amount
          successTransactions
          failedTransactions
        }
      }
      allData {
        time
        data {
          amount
          successTransactions
          failedTransactions
        }
      }
    }
  }
`;

export const getFullTransactionHistoryData = gql`
  query getFullTransactionHistoryData($refData: RefIdDataParams!) {
    sendTransactionHistory(refData: $refData) {
      refID
      request {
        bankId
        additionalInfo {
          tag {
            name
            value
          }
        }
        agent {
          id
          device {
            tag {
              name
              value
            }
          }
        }
        amount {
          splitPayAmount
          amt {
            amount
            currency
            custConvFee
          }
          tag {
            name
            value
          }
        }
        analytics {
          tag {
            name
            value
          }
        }
        billDetails {
          biller {
            id
          }
          customerParams {
            tag {
              name
              value
            }
          }
        }
        billerResponse {
          amount
          billDate
          customerName
          tag {
            name
            value
          }
        }
        customer {
          mobile
          tag {
            name
            value
          }
        }
        head {
          origInst
          refId
          siTxn
          ts
          ver
        }
        paymentMethod {
          OFFUSPay
          paymentMode
          quickPay
          splitPay
        }
        txn {
          msgId
          ts
          txnReferenceId
          type
        }
      }
      isDeleted
      cuResponse
      billerAck
      negativeAck
      createdAt
      updatedAt
      response {
        billDetails {
          customerParams {
            tag {
              name
              value
            }
          }
        }
        billerResponse {
          amount
          billDate
          customerName
          tag {
            name
            value
          }
        }
        head {
          origInst
          refId
          siTxn
          ts
          ver
        }
        reason {
          approvalRefNum
          responseCode
          responseReason
        }
        txn {
          msgId
          ts
          txnReferenceId
          type
        }
      }
      webhookCallTimeStampByProxy
      billerAckTimeStamp
      paymentPostResponseFromProxy {
        refId
      }
      paymentPostResponseFromProxyTimeStamp
    }
  }
`;

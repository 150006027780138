import { ApolloClient, InMemoryCache, HttpLink, from } from "@apollo/client";
import { onError } from "@apollo/client/link/error";

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message }) =>
      console.error(`[GraphQL Error]: ${message}`)
    );
  }
  if (networkError) {
    console.error(`[Network Error]: ${networkError}`);
  }
});

// Retrieve the token from local storage or wherever it is stored
const token = sessionStorage.getItem("biller_access");

const link = from([
  errorLink,
  new HttpLink({
    uri: `${process.env.REACT_APP_GRAPH_QL_URI}`,
    headers: {
      Authorization: token ? `Bearer ${token}` : "",
    },
  }),
]);

const defaultOptions = {
  watchQuery: {
    fetchPolicy: "no-cache",
    errorPolicy: "ignore",
  },
  query: {
    fetchPolicy: "no-cache",
    errorPolicy: "all",
  },
};

const client = new ApolloClient({
  link: link,
  cache: new InMemoryCache({
    addTypename: false,
  }),
  defaultOptions: defaultOptions,
});

export default client;

export const downloadReconFile = async (fileUrl) => {
  // const fileUrl = "https://storage.googleapis.com/bbps-dev-b01/recon-settlements/data_2025-01-21T11:08:31.782Z.json.gz"
  try {
    // Fetch the file content
    const response = await fetch(fileUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch the file. Status: ${response.status}`);
    }

    // Convert response to blob
    const blob = await response.blob();

    if (blob.size === 0) {
      throw new Error("Received empty file.");
    }

    // Create a temporary URL for the blob
    const downloadUrl = window.URL.createObjectURL(blob);

    // Extract the file name or use a default name
    const fileName = fileUrl?.split("/")?.pop() || "downloaded-file.json";

    // Create an anchor element and simulate a click
    const anchor = document.createElement("a");
    anchor.href = downloadUrl;
    anchor.download = fileName;
    document.body.appendChild(anchor); // Attach to the DOM
    anchor.click(); // Trigger the download
    document.body.removeChild(anchor); // Clean up the DOM

    // Revoke the object URL
    window.URL.revokeObjectURL(downloadUrl);
  } catch (error) {
    console.error("Download failed:", error.message);
  }
};

import React, { useEffect } from "react";
import { DatePicker, Select, Button } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";

const { Option } = Select;

export const UXmlZipFilter = ({
  handleSubmit,
  setFormData,
  formData,
  submitButton,
}) => {
  const isFormValid = formData.cycleNo;
  const { uploadXmlZipFile } = useSelector((state) => {
    return {
      uploadXmlZipFile: state?.reconReducer?.uploadXmlFileZip,
    };
  });

  useEffect(() => {
    if (uploadXmlZipFile?.response !== null) {
      setFormData({
        ...formData,
        cycleNo: "",
      });
    }
  }, [uploadXmlZipFile]);

  return (
    <div className="form-container" style={{ display: "flex", width: "75vw" }}>
      <Select
        allowClear
        suffixIcon={<span style={{ color: "red",fontSize:'20px' }}>*</span>}
        disabled={uploadXmlZipFile?.loading === true}
        placeholder="Select Cycle No."
        className="filter-select"
        value={formData.cycleNo}
        onChange={(value) => setFormData({ ...formData, cycleNo: value })}
      >
        <Option value="">Select a cycle No.</Option>
        {[...Array(8)].map((_, i) => (
          <Option key={i} value={i + 1}>
            Cycle {i + 1}
          </Option>
        ))}
      </Select>

      <Button
        type="primary"
        className="filter-button"
        onClick={handleSubmit}
        loading={uploadXmlZipFile?.loading === true}
        disabled={
          !isFormValid || uploadXmlZipFile?.loading === true || submitButton
        }
      >
        {uploadXmlZipFile?.loading === true ? "Uploading...." : "Submit"}
      </Button>
    </div>
  );
};

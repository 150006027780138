import React from "react";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { errorPopup } from "../../../Services/toasterMessage";
import "./index.scss"

const DnD = ({ setFormDataState }) => {
  const uploadProps = {
    name: "file",
    multiple: false,
    beforeUpload(file) {
      const allowedTypes = [
        "application/vnd.ms-excel",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        "text/plain",
      ];

      if (!allowedTypes.includes(file.type)) {
        message.error(`${file.name} is not a valid file type.`);
        // errorPopup(`${file.name} is not a valid file type.`)
        return Upload.LIST_IGNORE;
      }

      const isSizeValid = file.size / 1024 / 1024 < 100;
      if (!isSizeValid) {
        message.error(`${file.name} exceeds the size limit.`);
        // errorPopup(`${file.name} exceeds the size limit.`);
        return Upload.LIST_IGNORE;
      }

      setFormDataState(file); 
      return false; 
    },
  };

  return (
    <Upload.Dragger {...uploadProps} style={{ width: "75vw" }}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">Click or drag a file to this area to upload</p>
      <p className="ant-upload-hint">
        Only Excel (.xls, .xlsx) and text (.txt) files are allowed. One file at a time.
      </p>
    </Upload.Dragger>
  );
};

export default DnD;

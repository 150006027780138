import { Button, Space, Spin, Table } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { downloadReconFile } from "../../../utils/downloadReconFile";

export const FetchFileTable = () => {
  const { profileData } = useSelector((state) => {
    return {
      profileData: state?.authReducer?.profileData,
    };
  });
  const [data, setData] = useState([]);

  const { newReport } = useSelector((state) => {
    return {
      newReport: state?.reconReducer?.uploadReconData?.newReport,
    };
  });
  const columns = [
    { title: "Name", dataIndex: "name" },
    { title: "From Time", dataIndex: "fromTime" },
    { title: "To Time", dataIndex: "toTime" },
    { title: "Duration In Hrs.", dataIndex: "duration" },
  ];
  useEffect(() => {
    const fetchedData = newReport?.response?.url?.map((file, index) => ({
      name: `Transaction-${index + 1}`,
      fromTime: "N/A",
      toTime: "N/A",
      duration: "N/A",
      date: file.date || "N/A",
      fileUrl: file,
    }));
    setData(fetchedData);
  }, [newReport?.response]);
  return (
    <div>
      <Table
        columns={[
          ...columns,
          {
            title: "Actions",
            render: (_, record) => (
              <Space>
                <Button
                  onClick={() => downloadReconFile(record.fileUrl)}
                  style={{ cursor: "pointer" }}
                  className="filter-button"
                >
                  Download File
                </Button>
              </Space>
            ),
          },
        ]}
        rowKey={(record) => record?.name}
        loading={newReport?.loading}
        dataSource={profileData?.internal ? data : data?.slice(-1)}
        pagination={{
          total: newReport?.response?.pagination?.total || 10,
          current: newReport?.response?.pagination?.currentPage,
          pageSize: newReport?.response?.pagination?.itemsPerPage,
          showSizeChanger: true,
          showQuickJumper: true,
          pageSizeOptions: ["5", "10", "20"],
        }}
      />
    </div>
  );
};

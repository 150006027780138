import { useEffect, useState, useCallback } from "react";
import { message } from "antd";
import { useSelector } from "react-redux";

const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes in milliseconds
const CHECK_INTERVAL = 60 * 1000; // Check every minute

const InactivityLogout = () => {
  const [lastActivity, setLastActivity] = useState(Date.now());
  const [isLoggedOut, setIsLoggedOut] = useState(false);

  const { profileData } = useSelector((state) => {
    return {
      profileData: state?.authReducer?.profileData,
    };
  });

  const notForLogoutEmail = "plutos@idfcfirstbank.com";

  // Memoize handleLogout to prevent unnecessary re-renders
  const handleLogout = useCallback(() => {
    if (!isLoggedOut) {
      // Prevent multiple logouts
      sessionStorage.removeItem("biller_access");
      localStorage.removeItem("module_access_bou");
      setIsLoggedOut(true);
      window.location.replace(`${window.location.origin}/login`);
      console.log("User logged out due to inactivity");
    }
  }, [isLoggedOut]);

  // Memoize updateActivity to prevent unnecessary re-renders
  const updateActivity = useCallback(() => {
    const currentTime = Date.now();
    // Only update if the user is still logged in
    if (!isLoggedOut) {
      setLastActivity(currentTime);
    }
  }, [isLoggedOut]);

  useEffect(() => {
    // Check if user is already logged out
    const isTokenValid = sessionStorage.getItem("biller_access") !== null;
    // If user is not logged in and it's not Pluto's email, just log out
    if (!isTokenValid && profileData?.email !== notForLogoutEmail) {
      handleLogout();
      return;
    }

    const checkInactivity = () => {
      const currentTime = Date.now();
      if (
        currentTime - lastActivity >= INACTIVITY_TIMEOUT &&
        profileData?.email !== notForLogoutEmail
      ) {
        handleLogout();
      }
    };

    // Set up activity listeners
    const activityEvents = [
      "mousemove",
      "mousedown",
      "keydown",
      "touchstart",
      "scroll",
      "click",
    ];

    // Add event listeners
    activityEvents.forEach((event) => {
      document.addEventListener(event, updateActivity, { passive: true });
    });

    // Set up inactivity checker
    const intervalId = setInterval(checkInactivity, CHECK_INTERVAL);

    // Cleanup function
    return () => {
      // Remove event listeners
      activityEvents.forEach((event) => {
        document.removeEventListener(event, updateActivity);
      });
      clearInterval(intervalId);
    };
  }, [lastActivity, handleLogout, updateActivity, profileData?.email]); // Include all dependencies

  // Optional: Show a warning modal when getting close to timeout
  useEffect(() => {
    const warningTimeout = INACTIVITY_TIMEOUT - 1 * 60 * 1000; // 1 minute before logout
    const checkForWarning = () => {
      const currentTime = Date.now();
      const timeSinceLastActivity = currentTime - lastActivity;

      if (
        timeSinceLastActivity > warningTimeout &&
        !isLoggedOut &&
        profileData?.email !== notForLogoutEmail
      ) {
        message.warning({
          content: "Session about to expire in 1 minute",
          duration: 3,
        });
        // You can add UI notification here
      }
    };

    const warningIntervalId = setInterval(checkForWarning, CHECK_INTERVAL);

    return () => {
      clearInterval(warningIntervalId);
    };
  }, [lastActivity, isLoggedOut, profileData?.email]);

  // No need to render anything unless showing a warning
  return null;
};

export default InactivityLogout;

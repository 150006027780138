import React, { useEffect, useState } from "react";
import { DatePicker, Select, Button, Upload, message, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/ReconAndSettlement/actions";
import DnD from "./Dnd";
import "./index.scss";

const { Option } = Select;
const { RangePicker } = DatePicker;
const {
  getReconData,
  getReconDataReset,
  getReconDataNotified,
  UploadFileNewReport,
  uploadDataResetNewReport,
  uploadDataNotifiedNewReport,
  UploadFileSuccessNewReport,
  UploadFileFailureNewReport,
} = actions;
const Filter = ({ selectedFile, setSelectedFile, loading }) => {
  const dispatch = useDispatch();
  const [messageApi, contextHolder] = message.useMessage();
  const [dropdown1Value, setDropdown1Value] = useState(null);
  const [dropdown2Value, setDropdown2Value] = useState(null);
  const [billerId, setBillerId] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showForm, setShowForm] = useState(true);
  const [file, setFile] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  // Handlers
  const handleDropdown1Change = (value) => setDropdown1Value(value);
  const handleDropdown2Change = (value) => setDropdown2Value(value);
  const handleBillerIdchange = (value) => setBillerId(value);
  const handleDateChange = (date, dateString) => setSelectedDate(dateString);

  // reducer usage
  const { newReport } = useSelector((state) => {
    return {
      newReport: state.reconReducer?.uploadReconData?.newReport,
    };
  });

  const { totalAgent } = useSelector((state) => state.authReducer);

  const handleFormSubmit = () => {
    if (dropdown1Value && dropdown2Value && selectedDate) {
      setSelectedFile({
        cycleNo: dropdown2Value,
        fileType: dropdown1Value,
        date: selectedDate,
        billerId: billerId,
      });
      dispatch(
        UploadFileNewReport({
          cycleNo: dropdown2Value,
          date: selectedDate,
          fileType: dropdown1Value,
          billerId: billerId,
        })
      );
    } else {
      message.warning("Please fill in all fields.");
    }
  };

  useEffect(() => {
    if (
      newReport?.error !== null &&
      !newReport?.response?.message?.length > 0 &&
      newReport?.loading === false
    ) {
      messageApi.destroy();
      messageApi
        .open({
          type: "error",
          content:
            newReport?.error?.length > 0 &&
            newReport?.error !== null &&
            newReport?.error,
          duration: 1,
        })
        .then(() => dispatch(uploadDataResetNewReport()));
    } else if (
      newReport?.data?.message?.length > 0 &&
      newReport?.error === null &&
      newReport?.loading === false &&
      newReport?.notified === false
    ) {
      if (!newReport?.data?.url.length > 0) {
        messageApi.destroy();
        messageApi
          .open({
            type: "info",
            content: "No transaction sheet url available",
            duration: 3,
          })
          .then(() => dispatch(uploadDataNotifiedNewReport()));
      } else {
        messageApi.destroy();
        messageApi
          .open({
            type: "success",
            content:
              newReport?.data?.message?.length > 0 &&
              newReport?.data?.url?.length > 0 &&
              newReport?.data?.message,
            duration: 1,
          })
          .then(() => dispatch(uploadDataNotifiedNewReport()));
      }
    }
  }, [newReport]);

  return (
    <div className="filter-container">
      {contextHolder}
      {/* {showForm ? ( */}
      <div className="form-container">
        <Select
          allowClear
          suffixIcon={<span style={{ color: "red", fontSize: "20px" }}>*</span>}
          placeholder="Select File Type"
          className="filter-select"
          onChange={handleDropdown1Change}
        >
          <Option value="XML">Xml File</Option>
          <Option value="JSON">Json File</Option>
          <Option value="Match">Match File</Option> 
          <Option value="Match_Internal">Match Internal File</Option> 
          <Option value="Unmatch">Unmatch File</Option>
          <Option value="Unmatch_Internal">Unmatch Internal File</Option>
          <Option value="SAL_File">Sal File</Option>
          <Option value="Transaction_Report">Transaction Report</Option>
          <Option value="Settlement_Report">Settlement Report</Option>
          <Option value="None">None</Option>
        </Select>

        <Select
          allowClear
          suffixIcon={<span style={{ color: "red", fontSize: "20px" }}>*</span>}
          placeholder="Select Cycle No."
          className="filter-select"
          onChange={handleDropdown2Change}
        >
          {[...Array(8)].map((_, i) => (
            <Option key={i} value={i + 1}>
              Cycle {i + 1}
            </Option>
          ))}
        </Select>

        <Select
          allowClear
          placeholder="Select Biller Id."
          className="filter-select"
          onChange={handleBillerIdchange}
        >
          <Option value="">Select a biller id</Option>
          {totalAgent?.organizations?.map((item, index) => (
            <Option key={item.orgId} value={item.orgId}>
              {item.companyName}
            </Option>
          ))}
        </Select>

        <DatePicker
          suffixIcon={<span style={{ color: "red", fontSize: "20px" }}>*</span>}
          className="filter-date-picker"
          disabledDate={(date) => date > new Date()}
          onChange={handleDateChange}
          placeholder="Select a date"
        />
{/* 
        <RangePicker
          suffixIcon={<span style={{ color: "red", fontSize: "20px" }}>*</span>}
          className="filter-date-picker"
          disabledDate={(date) => date > new Date()}
          onChange={handleDateChange}
          placeholder="Select a date"
          format={"YYYY-MM-DD"}
        /> */}

        <Button onClick={handleFormSubmit} className="filter-button">
          Submit
        </Button>
      </div>
      {/* // ) : (
      //   <div className="upload-container">
      //     <Upload>
      //     <DnD setFormDataState={setFile}/>
      //     </Upload> 
      //   </div>
      // )} */}
    </div>
  );
};

export default Filter;

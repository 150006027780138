import React, { useEffect, useMemo, useState } from "react";
import "./transaction.scss";
import moment from "moment";
import { JSONTree } from "react-json-tree";
import {
  Button,
  DatePicker,
  message,
  Modal,
  notification,
  Popover,
  Select,
  Space,
  Spin,
  Table,
  Tag,
  TimePicker,
} from "antd";
import { CalendarOutlined, DownloadOutlined } from "@ant-design/icons";
import { FaAngleDown } from "react-icons/fa";
import { RiCodeSSlashFill, RiCodeSSlashLine } from "react-icons/ri";
import { RiCodeSLine } from "react-icons/ri";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import transactionActions from "../../redux/transactions/actions";
import { CSVLink } from "react-csv";
import SearchBar from "../../utils/Searchbar";
import { handleExportFile } from "../../utils/exportTransaction";
import CountUp from "react-countup";
import TransactionExport from "./TransactionExport";
const { Option } = Select;
const { RangePicker } = DatePicker;

const {
  getTransactionsData,
  getAllTransactionsData,
  resetAllTransactionsData,
  getFullTransactionDetails,
  getFullTransactionsDetailsReset,
} = transactionActions;
function Transactions() {
  const dispatch = useDispatch();
  const { transactionData, allTransactions } = useSelector(
    (state) => state.transactionReducer
  );

  const { fullTransactionDetails } = useSelector((state) => {
    return {
      fullTransactionDetails: state.transactionReducer?.fullTransactionDetails,
    };
  });

  const { agentId } = useSelector((state) => ({
    agentId: state.authReducer.agentId,
  }));

  const [api, contextHolder] = notification.useNotification();

  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [alertNotify, setAlertNotify] = useState(false);
  const [tableParams, setTableParams] = useState({
    current: 1,
    pageSize: 10,
  });
  const [toggleTransactionDetailsModal, setToggleTransactionDetailsModal] =
    useState(true);
  const [toggleTransactionKey, setToggleTransactionKey] = useState("");
  const now = moment();
  const formattedDate = now.format("YYYY-MM-DD");
  const formattedTime = now.format("HH-mm-ss");
  const newFilename = `transactions_data_${formattedDate}_${formattedTime}.csv`;
  const [searchQuery, setSearchQuery] = useState("");
  const [timeoutId, setTimeoutId] = useState(null);
  const [fullSheetDateExport, setFullSheetDateExport] = useState(null);
  const [pickerType, setPickerType] = useState("date");
  const [fromTime, setFromTime] = useState(null); // Time state for "from"
  const [endTime, setEndTime] = useState(null); // Time state for "to"
  const [transactionStatusFilter, setTransactionStatusFilter] = useState("");

  //for refund pages
  const navigate = useNavigate();
  const HandleChange = () => {
    navigate("/refund");
  };

  const getDynamicColumns = (transactions) => {
    const tags = [];

    transactions?.forEach((transaction) => {
      transaction?.customerParams?.tag?.forEach((tag) => {
        if (!tags.some((existingTag) => existingTag?.name === tag?.name)) {
          tags.push(tag);
        }
      });
    });

    // Map tags to columns
    return tags?.map((tag) => ({
      title: tag?.name, // Column title will be the name of the tag
      dataIndex: tag?.name, // Column dataIndex will be the value (or unique identifier)
    }));
  };

  // Memoize the dynamic columns to avoid recalculating on every render
  const dynamicColumns = useMemo(
    () => getDynamicColumns(transactionData?.data?.transactions),
    [transactionData]
  );

  const columns = [
    {
      title: "#",
      dataIndex: "key",
      key: "key",
      sorter: (a, b) => a?.key - b?.key,
      width: 100, // Fixed width
    },
    {
      title: "DATE-TIME",
      dataIndex: "date",
      sorter: (a, b) => Date?.parse(a?.createdAt) - Date?.parse(b?.createdAt), // Compare raw date-time
      width: 100, // Fixed width
      render: (text, record) => (
        <>
          <p className="m-0 p-0">
            {moment(record?.createdAt)?.format("DD MMMM YYYY")}
          </p>
          <p className="m-0 p-0">
            {moment(record?.createdAt)?.format("hh:mm:ss A")}
          </p>
        </>
      ),
    },
    {
      title: "TRANSACTION ID",
      dataIndex: "transaction_id",
      sorter: (a, b) => a?.transaction_id?.localeCompare(b?.transaction_id),
      width: 100, // Fixed width
    },
    ...dynamicColumns?.map((item) => ({
      title: item?.title?.toUpperCase(), // Convert title to uppercase
      dataIndex: item?.dataIndex, // Ensure you keep the dataIndex
      width: item?.width || 100, // Use a default width if not provided
    })),
    // {
    //   title: "PARTICIPANT CODE",
    //   dataIndex: "participant_code",
    // },
    {
      title: "PAYMENT MODE",
      dataIndex: "payment_mode",
      width: 100, // Fixed width
    },
    // {
    //   title: "BILLER",
    //   dataIndex: "biller_name",
    // },
    {
      title: "BILL AMOUNT",
      dataIndex: "billAmount",
      sorter: (a, b) => parseInt(a?.billAmount) - parseInt(b?.billAmount),
      render: (text) => `₹${text}`,
      width: 100, // Fixed width
    },
    {
      title: "PAID AMOUNT",
      sorter: (a, b) => parseInt(a?.amount) - parseInt(b?.amount),
      render: (text) => `₹${text}`,
      dataIndex: "amount",
    },
    // {
    //   title: "IDF RECIEPTNUMBER",
    //   dataIndex: "idfreceiptnumber",
    // },
    // {
    //   title: "BILLER CATEGORY",
    //   dataIndex: "biller_category_name",
    // },
    // {
    //   title: "ENTRANSID",
    //   dataIndex: "entransId",
    // },
    {
      title: "BILLER ID",
      dataIndex: "billerId",
    },
    {
      title: "TRANSACTION STATUS",
      dataIndex: "status",
      width: 100, // Fixed width
    },
    {
      title: "FULL TRANSACTION DETAILS",
      dataIndex: "allTransactionDetails",
    },
    // {
    //   title: "RESPONSE MESSAGE",
    //   dataIndex: "repsonse_msg",
    // },
  ];

  const handleViewTransactionDetails = (data) => {
    dispatch(getFullTransactionDetails(data.refId));
    setToggleTransactionDetailsModal(!toggleTransactionDetailsModal);
    setToggleTransactionKey(data.refId);
  };

  var data =
    transactionData?.data?.transactions &&
    transactionData?.data?.transactions?.length > 0 &&
    transactionData?.data?.transactions?.map((items, index) => {
      const customerTags = items?.customerParams?.tag || [];

      // Create a new object for each row
      const rowData = {
        key: (tableParams.current - 1) * tableParams.pageSize + index + 1,
        transaction_id: items.transactionId,
        billerId: items?.billerId,
        biller_name: items?.biller?.billerName,
        biller_category_name: items?.biller?.billerCategoryName,
        payment_mode: items?.paymentMode,
        amount: items?.amount ? `${parseInt(items?.amount / 100)}` : 0,
        billAmount: items?.billAmount ? `${parseInt(items?.billAmount / 100)}` : 0,
        date: (
          <>
            <p className="m-0 p-0">
              {moment(items?.createdAt)?.format("DD MMMM YYYY")}
            </p>
            <p className="m-0 p-0">
              {moment(items?.createdAt)?.format("hh:mm:ss A")}
            </p>
          </>
        ),
        createdAt: items.createdAt,
        status: (
          <Tag
            color={
              items?.status?.toLowerCase() === "successful" &&
              items?.cuResponse?.toLowerCase() === "successful"
                ? "green"
                // : items?.status?.toLowerCase() === "pending" &&
                //   ["pending", "successful"]?.includes(
                //     items?.cuResponse?.toLowerCase()
                //   )
                // ? "yellow"
                : "red"
            }
            className="draft"
          >
            {items?.status?.toLowerCase() === "successful" &&
            items?.cuResponse?.toLowerCase() === "successful"
              ? "Successful"
              // : items?.status?.toLowerCase() === "pending" &&
              //   ["pending", "successful"]?.includes(
              //     items?.cuResponse?.toLowerCase()
              //   )
              // ? "Pending"
              : "Failure"}
          </Tag>
        ),
        allTransactionDetails: (
          <span
            onClick={() => handleViewTransactionDetails(items)}
            style={{
              display: "flex",
              justifyContent: "center",
              cursor: "pointer",
            }}
          >
            {toggleTransactionDetailsModal === false &&
            toggleTransactionKey === items?.refId ? (
              <RiCodeSLine size={25} color="#073763" />
            ) : (
              <RiCodeSSlashLine size={25} />
            )}
          </span>
        ),
      };

      // Dynamically add each tag value based on tag name
      customerTags?.forEach((tag) => {
        rowData[tag?.name] = tag?.value;
      });

      return rowData;
    });

  // let timeoutId;
  const handleSearch = (e) => {
    e.preventDefault();
    const value = e.target.value.trim(); // Get the search query

    setSearchQuery(value); // Set the search query immediately

    // Clear any existing timeout
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    // Set a new timeout
    const newTimeoutId = setTimeout(() => {
      if (
        value.trim().length > 1 &&
        transactionData?.data?.transactions?.length >= 0
      ) {
        dispatch(
          getTransactionsData(
            1, // Starting page
            10, // Number of records per page
            value, // Search query
            fromDate?.format("YYYY-MM-DDTHH:mm:ss:000+05:30"),
            toDate?.format("YYYY-MM-DDTHH:mm:ss:999+05:30"),
            agentId,
            transactionStatusFilter
          )
        );
      } else if (value.length === 0) {
        dispatch(
          getTransactionsData(
            1,
            10,
            "",
            fromDate?.format("YYYY-MM-DDTHH:mm:ss:000+05:30"),
            toDate?.format("YYYY-MM-DDTHH:mm:ss:999+05:30"),
            agentId,
            transactionStatusFilter
          )
        );
      }
    }, 1000);

    // Update the timeoutId state
    setTimeoutId(newTimeoutId);

    setTableParams((prev) => ({
      current: 1, // Reset the current page to 1
      pageSize: 10, // Set the page size to 10
    }));
  };

  const handlePageChange = async (pagination, filters, sorter) => {
    const { current, pageSize } = pagination;
    setTableParams({ current, pageSize });

    if (fromDate && toDate) {
      dispatch(
        getTransactionsData(
          current,
          pageSize,
          searchQuery,
          fromDate?.format("YYYY-MM-DDTHH:mm:ss:000+05:30"),
          toDate?.format("YYYY-MM-DDTHH:mm:ss:999+05:30"),
          agentId,
          transactionStatusFilter
        )
      );
    } else {
      dispatch(
        getTransactionsData(
          current,
          pageSize,
          searchQuery,
          "",
          "",
          agentId,
          transactionStatusFilter
        )
      );
    }
  };

  // console.log(agentId , "agentId")
  const handleFromDateChange = (date) => {
    if (
      date?.isSame(moment(), "day") &&
      (date?.hour() > moment().hour() ||
        (date?.hour() === moment().hour() &&
          date?.minute() > moment().minute()) ||
        (date?.hour() === moment().hour() &&
          date?.minute() === moment().minute() &&
          date?.second() > moment().second()))
    ) {
      setFromDate(
        date
          .clone()
          .hour(moment().hour())
          .minute(moment().minute())
          .second(moment().second())
      );
    } else {
      setFromDate(date);
    }
    if (date > toDate) {
      setToDate(null);
    }
  };

  const handleToDateChange = (date) => {
    if (date?.isAfter(moment(), "day")) {
      setToDate(moment());
    } else if (date?.isSame(moment(), "day")) {
      if (
        date?.isSame(fromDate, "day") &&
        date?.hour() === 0 &&
        date?.minute() === 0 &&
        date?.second() === 0
      ) {
        setToDate(
          date
            .clone()
            .hour(moment().hour())
            .minute(moment().minute())
            .second(moment().second())
        );
      } else if (
        date?.isSame(fromDate, "day") &&
        (date?.hour() > moment().hour() ||
          (date?.hour() === moment().hour() &&
            date?.minute() > moment().minute()) ||
          (date?.hour() === moment().hour() &&
            date?.minute() === moment().minute() &&
            date?.second() > moment().second()))
      ) {
        setToDate(
          date
            .clone()
            .hour(moment().hour())
            .minute(moment().minute())
            .second(moment().second())
        );
      } else if (
        date?.hour() > moment().hour() ||
        (date?.hour() === moment().hour() &&
          date?.minute() > moment().minute()) ||
        (date?.hour() === moment().hour() &&
          date?.minute() === moment().minute() &&
          date?.second() > moment().second())
      ) {
        setToDate(
          date
            .clone()
            .hour(moment().hour())
            .minute(moment().minute())
            .second(moment().second())
        );
      } else {
        setToDate(date);
      }
    } else {
      if (
        date?.isSame(fromDate, "day") &&
        date?.hour() === 0 &&
        date?.minute() === 0 &&
        date?.second() === 0
      ) {
        setToDate(date.clone().hour(23).minute(59).second(59));
      } else {
        setToDate(date);
      }
    }
    if (date && date < fromDate) {
      setFromDate(null);
    }
  };

  const disabledToDate = (current) => {
    return (
      (fromDate && current && current < fromDate?.startOf("day")) ||
      current > moment().endOf("day")
    );
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  useEffect(() => {
    dispatch(resetAllTransactionsData());
  }, [agentId, fromDate, toDate, dispatch]);

  useEffect(() => {
    if (!fromDate && !toDate) {
      dispatch(
        getTransactionsData(
          1,
          10,
          searchQuery,
          fromDate?.format("YYYY-MM-DDTHH:mm:ss:000+05:30"),
          toDate?.format("YYYY-MM-DDTHH:mm:ss:999+05:30"),
          agentId,
          transactionStatusFilter
        )
      );
    } else if (fromDate && toDate) {
      dispatch(
        getTransactionsData(
          1,
          10,
          searchQuery,
          fromDate?.format("YYYY-MM-DDTHH:mm:ss:000+05:30"),
          toDate?.format("YYYY-MM-DDTHH:mm:ss:999+05:30"),
          agentId,
          transactionStatusFilter
        )
      );
    } else if (((!fromDate&&!toDate)||(fromDate&&toDate))&&transactionStatusFilter) {
      dispatch(
        getTransactionsData(
          1,
          10,
          searchQuery,
          fromDate?.format("YYYY-MM-DDTHH:mm:ss:000+05:30"),
          toDate?.format("YYYY-MM-DDTHH:mm:ss:999+05:30"),
          agentId,
          transactionStatusFilter
        )
      );
    } else if (fromDate === null || toDate === null) {
      return;
    }

    // Reset the table params to default pagination state
    setTableParams((prev) => ({
      ...prev,
      current: 1,
      pageSize: 10,
    }));
  }, [agentId, dispatch, fromDate, toDate, transactionStatusFilter]);

  const handleCopyClick = () => {
    navigator?.clipboard
      ?.writeText(
        JSON.stringify(
          fullTransactionDetails?.data !== ""
            ? fullTransactionDetails?.data
            : {}
        )
      )
      ?.then(() => {
        message.success("Copied to clipboard!");
      })
      .catch(() => {
        message.error("Failed to copy!");
      });
  };

  // function to close the full transaction details modal
  const handleTransactionDetailsModalClose = () => {
    setToggleTransactionDetailsModal(true);
    dispatch(getFullTransactionsDetailsReset());
  };

  // will update this function later to refactor date time filter functionality
  const handleRangePicker = (value) => {
    setFromDate(value[0]?.format("YYYY-MM-DD"));
    setToDate(value[1]?.format("YYYY-MM-DD"));
    setFromTime(value[0]?.format("HH:mm:ss"));
    setEndTime(value[1]?.format("HH:mm:ss"));
  };

  return (
    <div className="mainContain">
      {contextHolder}
      <h1 className="transaction">Transactions</h1>
      <div className="d-flex gap-3" style={{ cursor: "pointer" }}>
        {/* <p className="suntag">Transaction</p>
        <p onClick={HandleChange} className="suntaginactive">
          Refund
        </p> */}
      </div>
      <div className="datesearchFi">
        <div className="">
          <SearchBar onChange={handleSearch} loading={transactionData?.loading===true?true:false} />
        </div>
        <div className="d-flex align-items-center gap-2">
          <div className="ms-3">
            <Select
              style={{ width: "150px", height: "40px" }}
              placeholder="Select Status"
              value={transactionStatusFilter}
              disabled={transactionData?.loading===true}
              onChange={(value) => {
                setTransactionStatusFilter(value);
              }}
            >
              <Option value="">Select Status</Option>
              <Option value="Successful">Successful</Option>
              <Option value="Failure">Failure</Option>
              {/* <Option value="Pending">Pending</Option> */}
            </Select>
          </div>
          <div className="inputfatef">
            <div>
              <DatePicker
                value={fromDate}
                style={{ width: "100%", border: "none" }}
                onChange={handleFromDateChange}
                disabledDate={disabledDate}
                disabled={transactionData?.loading===true}
                format="DD-MM-YYYY HH:mm:ss"
                showTime
                placeholder="From (DD-MM-YYYY HH:mm:ss)" // Placeholder with time
                suffixIcon={<CalendarOutlined style={{ color: "#d9d3d3" }} />}
              />
            </div>
            <div>
              <DatePicker
                value={toDate}
                style={{ width: "100%", border: "none" }}
                onChange={handleToDateChange}
                disabledDate={disabledToDate}
                disabled={transactionData?.loading===true}
                format="DD-MM-YYYY HH:mm:ss"
                showTime
                changeOnScroll={false}
                placeholder="To (DD-MM-YYYY HH:mm:ss)" // Placeholder with time
                suffixIcon={<CalendarOutlined style={{ color: "#d9d3d3" }} />}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="cardClass">
        <div className="cardpayment">
          <div>Total Payment</div>
          <span>
            <CountUp
              useIndianSeparators={true}
              start={0}
              end={
                transactionData?.loading === true
                  ? 0
                  : transactionData?.data?.totalAmount / 100
              }
              prefix="₹"
            ></CountUp>
          </span>
        </div>
        <div className="cardpayment">
          <div>Total Transactions</div>
          <span>
            <CountUp
              useIndianSeparators={true}
              start={0}
              end={
                transactionData?.loading === true
                  ? 0
                  : transactionData?.data?.total
              }
            ></CountUp>
          </span>
        </div>
        <TransactionExport />
      </div>
      <div className="mt-3">
        <Table
          columns={columns}
          dataSource={data}
          loading={transactionData?.loading}
          scroll={{ x: 600 }}
          pagination={{
            total: transactionData?.data?.total,
            current: tableParams.current,
            pageSize: tableParams.pageSize,
            showSizeChanger: true,
            showQuickJumper: true,
            pageSizeOptions: ["10", "20", "50", "100", "200"],
          }}
          onChange={handlePageChange}
        />
      </div>
      {/* modal for full transaction logs */}
      <Modal
        title="Full Transaction Details"
        open={toggleTransactionDetailsModal === false}
        onOk={handleTransactionDetailsModalClose}
        onCancel={handleTransactionDetailsModalClose}
        width={700}
        centered
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleTransactionDetailsModalClose}>
            OK
          </Button>,
        ]}
      >
        <div className="py-2" style={{ position: "relative" }}>
          <Button
            key="copy"
            style={{
              position: "absolute",
              top: "20px",
              zIndex: 10,
              right: "30px",
            }}
            onClick={handleCopyClick} // On click, copy the content
          >
            Copy
          </Button>
          <div
            className="bg-white border rounded border-black code-modal-div"
            style={{
              padding: "10px",
              height: "480px",
              overflowY: "auto",
              borderRadius: "10px",
            }}
          >
            {fullTransactionDetails?.loading === true ? (
              <span
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "480px",
                }}
              >
                <Spin />
              </span>
            ) : (
              <>
                {transactionData?.loading === false &&
                fullTransactionDetails?.data !== "" &&
                fullTransactionDetails?.error === null ? (
                  <JSONTree
                    data={
                      fullTransactionDetails?.data !== ""
                        ? fullTransactionDetails?.data
                        : {}
                    }
                    theme={"bright:inverted"}
                  />
                ) : (
                  <JSONTree data={{}} theme={"bright:inverted"} />
                )}
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
}

export default Transactions;

import React, { useEffect } from "react";
import { DatePicker, Select, Button } from "antd";
import { useSelector } from "react-redux";
import moment from "moment";

const { Option } = Select;

const UFilter = ({ handleSubmit, setFormData, formData }) => {
  const isFormValid = formData.cycleNo && formData.fileType && formData.date;
  const { uploadWithoutBillerId, uploadByBillerId } = useSelector((state) => {
    return {
      uploadWithoutBillerId:
        state.reconReducer?.uploadReconData?.withoutBillerId,
      uploadByBillerId: state.reconReducer?.uploadReconData?.byBillerId,
    };
  });

  const { totalAgents } = useSelector((state) => {
    return {
      totalAgents: state?.authReducer?.totalAgent,
    };
  });

  // useEffect(()=>{
  //   if(uploadByBillerId?.response?.message?.length>0&&uploadByBillerId?.notified){
  //     setFormData({
  //       billerId:null,
  //       cycleNo: "",
  //       fileType: "",
  //       date: ""
  //     })
  //   }
  // },[uploadByBillerId?.notified])

  return (
    <div className="form-container"style={{width:'75vw'}}>
      <Select
        allowClear
        suffixIcon={<span style={{ color: "red",fontSize:'20px' }}>*</span>}
        disabled={uploadWithoutBillerId?.loading === true}
        placeholder="Select File Type"
        className="filter-select"
        onChange={(value) => setFormData({ ...formData, fileType: value })}
      >
        {/* <Option value="JSON">JSON File</Option> */}
        <Option value="Match">MATCH File</Option>
        <Option value="Unmatch">UNMATCH File</Option>
        <Option value="SAL_File">SAL File</Option>
        <Option value="XML">XML File</Option>
        <Option value="Missing">Missing File</Option>
        {/* <Option value="TXT">TXT</Option>
        <Option value="PIPE">PIPE</Option> */}
      </Select>
      <Select
        allowClear
        suffixIcon={<span style={{ color: "red",fontSize:'20px' }}>*</span>}
        disabled={uploadWithoutBillerId?.loading === true}
        placeholder="Select Cycle No."
        className="filter-select"
        onChange={(value) => setFormData({ ...formData, cycleNo: value })}
      >
        {[...Array(8)].map((_, i) => (
          <Option key={i} value={i + 1}>
            Cycle {i + 1}
          </Option>
        ))}
      </Select>
      {/* <Select
        disabled={uploadWithoutBillerId?.loading === true}
        placeholder="Select Biller Id."
        className="filter-select"
        onChange={(value) => setFormData({ ...formData, billerId: value })}
      >
        {totalAgents?.organizations?.map((item, index) => (
          <Option key={index} value={item?.orgId}>
           {item?.companyName}
          </Option>
        ))}
      </Select> */}
      <DatePicker
        suffixIcon={<span style={{ color: "red",fontSize:'20px' }}>*</span>}
        disabled={uploadWithoutBillerId?.loading === true}
        className="filter-select"
        disabledDate={(current) => current && current > moment().endOf("day")}
        onChange={(date, dateString) =>
          setFormData({ ...formData, date: dateString })
        }
        placeholder="Select a date"
      />
      <Button
        type="primary"
        className="filter-button"
        onClick={handleSubmit}
        loading={uploadWithoutBillerId?.loading === true}
        disabled={!isFormValid || uploadWithoutBillerId?.loading === true}
      >
        Submit
      </Button>
    </div>
  );
};

export default UFilter;

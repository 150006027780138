import actions from "./actions";

const initialState = {
  fetchReconData: {
    loading: false,
    data: {},
    error: null,
    notified: false,
  },
  uploadReconData: {
    newReport: {
      loading: false,
      error: null,
      response: null,
      notified: false,
    },
    withoutBillerId: {
      loading: false,
      error: null,
      response: null,
      notified: false
    }
  },
  uploadXmlFileZip: {
    loading: false,
    error: null,
    response: null,
    notified: false
  }
};

export default function reconReducer(state = initialState, action) {
  switch (action.type) {
    case actions.FETCH_FILE:
      return {
        ...state,
        fetchReconData: {
          ...state.fetchReconData,
          loading: true,
          notified: false,
          // data:action.payload,
          // error:null
        },
      };
    case actions.FETCH_DATA_SUCCESS:
      return {
        ...state,
        fetchReconData: {
          ...state.fetchReconData,
          loading: false,
          data: action.payload.data,
          error: null,
          notified: false,
        },
      };
    case actions.FETCH_DATA_FAILURE:
      return {
        ...state,
        fetchReconData: {
          ...state.fetchReconData,
          loading: false,
          data: {},
          error: action.payload.error,
          notified: false,
        },
      };
    case actions.FETCH_DATA_RESET:
      return {
        ...state,
        fetchReconData: {
          ...state.fetchReconData,
          loading: false,
          data: {},
          error: null,
          notified: false,
        },
      };
    case actions.FETCH_DATA_NOTIFIED:
      return {
        ...state,
        fetchReconData: {
          ...state.fetchReconData,
          notified: true,
        },
      };
    case actions.UPLOAD_FILE:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          withoutBillerId: {
            ...state.uploadReconData.withoutBillerId,
            loading: true,
          }
        },
      };
    case actions.UPLOAD_DATA_SUCCESS:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          withoutBillerId: {
            ...state.uploadReconData.withoutBillerId,
            loading: false,
            response: action.payload.data,
            error: null,
            notified: false,
          }
        },
      };
    case actions.UPLOAD_DATA_FAILURE:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          withoutBillerId: {
            ...state.uploadReconData.withoutBillerId,
            loading: false,
            response: null,
            error: action.payload.error,
            notified: false,
          }
        },
      };
    case actions.UPLOAD_DATA_RESET:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          withoutBillerId: {
            ...state.uploadReconData.withoutBillerId,
            loading: false,
            response: null,
            error: null,
            notified: false
          },
        },
      };
    case actions.UPLOAD_DATA_NOTIFIED:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          withoutBillerId: {
            ...state.uploadReconData.withoutBillerId,
            notified: true
          }
        },
      };
    case actions.UPLOAD_FILE_NEW_REPORT:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          newReport: {
            ...state.uploadReconData.newReport,
            loading: true,
          }
        },
      };
    case actions.UPLOAD_DATA_SUCCESS_NEW_REPORT:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          newReport: {
            ...state.uploadReconData.newReport,
            loading: false,
            response: action.payload.data,
            error: null,
            notified: false,
          }
        },
      };
    case actions.UPLOAD_DATA_FAILURE_NEW_REPORT:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          newReport: {
            ...state.uploadReconData.newReport,
            loading: false,
            response: null,
            error: action.payload.error,
            notified: false,
          }
        },
      };
    case actions.UPLOAD_DATA_RESET_NEW_REPORT:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          newReport: {
            ...state.uploadReconData.newReport,
            loading: false,
            response: null,
            error: null,
            notified: false
          },
        },
      };
    case actions.UPLOAD_DATA_NOTIFIED_NEW_REPORT:
      return {
        ...state,
        uploadReconData: {
          ...state.uploadReconData,
          newReport: {
            ...state.uploadReconData.newReport,
            notified: true
          }
        },
      };
    case actions.UPLOAD_XML_ZIP_FILE:
      return {
        ...state,
        uploadXmlFileZip: {
          ...state.uploadXmlFileZip,
          loading: true
        }
      }
    case actions.UPLOAD_XML_ZIP_FILE_SUCCESS:
      console.log(action.payload,"payload")
      return {
        ...state,
        uploadXmlFileZip: {
          ...state.uploadXmlFileZip,
          loading: false,
          response: action.payload.data,
          error: null,
          notified: false
        }
      }
    case actions.UPLOAD_XML_ZIP_FILE_FAILED:
      return {
        ...state,
        uploadXmlFileZip: {
          ...state.uploadXmlFileZip,
          loading: false,
          response: null,
          error: action.payload.error,
          notified: false
        }
      }
    case actions.UPLOAD_XML_ZIP_FILE_NOTIFIED:
      return {
        ...state,
        uploadXmlFileZip: {
          ...state.uploadXmlFileZip,
          notified: true
        }
      }
    case actions.UPLOAD_XML_ZIP_FILE_RESET:
      return {
        ...state,
        uploadXmlFileZip: {
          ...state.uploadXmlFileZip,
          loading:false,
          response:null,
          error:null,
          notified:false
        }
      }
    default:
      return state;
  }
}

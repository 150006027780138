import moment from 'moment';

export const PASSWORD_VALIDATE = new RegExp(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?=.*[^\s]).{12,}$/);



export function generateFilename(prefix) {
    const now = moment();
    const formattedDate = now.format('YYYY-MM-DD');
    const formattedTime = now.format('HH-mm-ss');
    return `${prefix}_${formattedDate}_${formattedTime}.csv`;
}
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import "./User.scss"
import { Button, Tag, Form, message, Popconfirm, Table, Switch } from 'antd';
// import Table from '../../utils/Table'
import userActions from '../../redux/user/actions'
import { EditOutlined, DeleteFilled, CheckCircleOutlined } from '@ant-design/icons';
import UserModal from './components/UserFormModel';
import moment from 'moment';
import SearchBar from '../../utils/Searchbar';

const columns = [
    {
        title: '#',
        dataIndex: 'key',
        key: 'key',
    },
    {
        title: 'NAME',
        dataIndex: 'name',
        sorter: (a, b) => a.name.localeCompare(b.name)
    },
    {
        title: 'EMAIL',
        dataIndex: 'email',
    },
    {
        title: 'ROLE',
        dataIndex: 'role',
        // sorter: (a, b) => a.role.localeCompare(b.role)
    },
    {
        title: 'CREATED DATE',
        dataIndex: 'created_date',
    },
    {
        title:"PASSWORD EXPIRY",
        dataIndex:"expiry_date"
    },
    {
        title: 'STATUS',
        dataIndex: 'status',

    },
    {
        title: 'ACTIONS',
        dataIndex: 'action',
    },
];

const { getUserData, getRoles, getModules, addUser, editUser, deleteUser, openForm, userReset, userResetMessage, userStatusChange } = userActions
function User() {
    const dispatch = useDispatch();
    const [UserForm] = Form.useForm();
    const [messageApi, contextHolder] = message.useMessage();
    const { userData, loading, totalData, rolesData, modulesData, error, show, buttonLoading } = useSelector((state) => state.userReducer)
    const sucessMessage = useSelector((state) => state.userReducer.message)
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [userId, setUserId] = useState();
    const [selectedModules, setSelectedModules] = useState([]);
    const [fromTitle, setfromTitle] = useState('');
    const [editFlag, setEditFlag] = useState(false);
    const [formButton, setformButton] = useState('');
    const [searchQuery,setSearchQuery]=useState('');
    const [tableParams, setTableParams] = useState({
        current: 1,
        pageSize: 10,
    });

    const {profileData}=useSelector((state)=>{
        return {
            profileData:state?.authReducer?.profileData
        }
    })

    useEffect(() => {
        dispatch(getUserData())
    }, [])

    useEffect(() => {
        if (sucessMessage !== null && sucessMessage !== undefined) {
            success(sucessMessage);
        }
        if (error !== null && error !== undefined) {
            errorHandle(error);
        }
    }, [sucessMessage, error]);





    const success = (text) => {
        const hide = messageApi.open({
            type: 'success',
            content: text,
        });
        setTimeout(hide, 4000);
        dispatch(userResetMessage());

    };
    const errorHandle = (text) => {
        const hide = messageApi.open({
            type: 'error',
            content: text,
        });
        setTimeout(hide, 4000);
        dispatch(userResetMessage());

    };


    const showModal = () => {
        dispatch(getModules())
        dispatch(getRoles())
        setfromTitle('ADD USER')
        setformButton('Submit')
        setEditFlag(false)
        dispatch(openForm())
        UserForm.resetFields();
        setSelectedModules([])
    };

    const handleEdit = (record) => {
        dispatch(getModules())
        dispatch(getRoles())
        setEditFlag(true);
        setUserId(record._id)
        setfromTitle('UPDATE USER')
        setformButton('Update')

        const permissions = Array.isArray(record?.permissions) ? record?.permissions : [];
        UserForm.setFieldsValue({
            name: record.name,
            role: record.roleId,
            email: record.email,
            permissions: permissions?.map((perm) => perm.module),
            ...permissions?.reduce((acc, curr) => {
                acc[`${curr.module}_permissions`] = [
                    curr.read ? 'read' : '',
                    curr.create ? 'create' : '',
                    curr.update ? 'update' : '',
                    curr.delete ? 'delete' : '',
                ].filter(Boolean);
                return acc;
            }, {}),
        });
        dispatch(openForm())
        setSelectedModules(permissions?.map((perm) => perm.module));
    }

    const handleCancel = () => {
        dispatch(userReset())
        UserForm.resetFields();
        setSelectedModules([])
    };


    const handleSubmit = (values) => {
        const modules = selectedModules.map((module) => ({
          module: module,
          read: values[`${module}_permissions`]?.includes("read") || true,
          create:
            values[`${module}_permissions`]?.includes("create") ||
            values?.role === "master admin" ||
            rolesData?.some(
              (item) =>
                item?.roleId === values?.role && item?.role === "master admin"
            )
              ? true
              : false,
          update:
            values[`${module}_permissions`]?.includes("update") ||
            values?.role === "master admin" ||
            rolesData?.some(
              (item) =>
                item?.roleId === values?.role && item?.role === "master admin"
            )
              ? true
              : false,
          delete:
            values[`${module}_permissions`]?.includes("delete") ||
            values?.role === "master admin" ||
            rolesData?.some(
              (item) =>
                item?.roleId === values?.role && item?.role === "master admin"
            )
              ? true
              : false,
        }));

        if (editFlag) {
            const payload = {
                role: values.role,
                permissions: (rolesData?.find((item)=>item?.roleId===values.role&&item.role==="master admin")?true:modules),
                status: "Active"
            }
            dispatch(editUser(userId, payload))
        } else {
            const payload = {
                name: values.name,
                email: values.email,
                password: values.password,
                role: values.role,
                permissions: modules
            }            
            dispatch(addUser(payload))
        }

    };


    const handleModuleChangenew = (module) => {
        if (selectedModules.includes(module)) {
            setSelectedModules(selectedModules.filter((m) => m !== module));
        } else {
            setSelectedModules([...selectedModules, module]);
        }
    };

    useEffect(()=>{
        dispatch(getRoles())
    },[])




    const dataSource = userData && userData?.length > 0 && userData?.map((user, index) => {
        const updatedUser = {
            ...user,
            role:rolesData?.find((item)=>item?.role===user?.role)?.roleId
        }
        return {
          key: (tableParams.current - 1) * tableParams.pageSize + index + 1,
          name: user.name,
          email: user.email,
          role: <Tag color="cyan">{user.role}</Tag>,
          created_date: moment(new Date(user?.createdAt)).format("MM/DD/YYYY"),
          expiry_date: moment(new Date(user?.createdAt))
            .add(90, "days")
            ?.format("MM/DD/YYYY"),
          status: (
            <Tag
              color={user.status === "Active" ? "green" : "red"}
              className="draft"
            >
              {user.status === "Active" ? "Active" : "Deactive"}
            </Tag>
          ),
          action: (
            <>
              {user?.role !== "master admin" &&
              (profileData?.role === "master admin" ||
                (profileData?.role !== "master admin" &&
                  profileData?.permissions?.find(
                    (permission) => permission?.module === "users"
                  ).update)) && (
                <span>
                  <EditOutlined
                    style={{ fontSize: "18px" }}
                    onClick={() => handleEdit(user)}
                  />
                </span>
              )}
              {user?.role !== "master admin" &&
              (profileData?.role === "master admin" ||
                (profileData?.role !== "master admin" &&
                  profileData?.permissions?.find(
                    (permission) => permission?.module === "users"
                  ).delete)) && (
                <span>
                  {user.status === "Active" ? (
                    <Popconfirm
                      title="Are you sure to Deactive this user"
                      okText="Yes"
                      cancelText="No"
                      placement="topRight"
                      onConfirm={() => dispatch(deleteUser(user._id))}
                    >
                      <DeleteFilled
                        style={{
                          fontSize: "18px",
                          color: "#e92121",
                          marginLeft: "10px",
                        }}
                      />
                    </Popconfirm>
                  ) : (
                    <Popconfirm
                      title="Are you sure to active this plan？"
                      okText="Yes"
                      cancelText="No"
                      placement="topRight"
                      onConfirm={() => dispatch(userStatusChange(updatedUser))}
                    >
                      <CheckCircleOutlined
                        style={{
                          fontSize: "18px",
                          color: "#2e830b",
                          marginLeft: "10px",
                        }}
                      />
                    </Popconfirm>
                  )}
                </span>
              )}
            </>
          ),
        };
    })


    let timeoutId;
    const handleFilterChange = (e) => {
        e.preventDefault()
        const value = e.target.value;
        if (timeoutId) {
            clearTimeout(timeoutId);
        }
        
        timeoutId = setTimeout(() => {
            if (value.length > 1) {
                setSearchQuery(value)
                dispatch(getUserData((tableParams.current-1)*tableParams.pageSize, tableParams.pageSize, value));
            }
            if (value.length < 1) {
                setSearchQuery('')
                dispatch(getUserData((tableParams.current-1)*tableParams.pageSize, tableParams.pageSize));
            }
            
        },1000)
    };

    const handleTableChange = async (pagination, filters, sorter) => {
        const { current, pageSize } = pagination;
        setTableParams({ current, pageSize });
        dispatch(getUserData(((current-1) * pageSize), pageSize,searchQuery));
    };

    return (
      <>
        {contextHolder}
        <div className="mainContain">
          <h1 className="transaction">Users</h1>
          <div className="d-flex justify-content-between align-items-center">
            <SearchBar onChange={handleFilterChange} />
            <Button
              disabled={
                profileData?.role !== "master admin" &&
                profileData?.permissions?.find(
                  (permission) => permission?.module === "users"
                )?.create === false
              }
              style={{
                backgroundColor: "#073763",
                borderColor: "#073763",
                color: "white",
                opacity: `${
                  profileData?.role !== "master admin" &&
                  profileData?.permissions?.find(
                    (permission) => permission?.module === "users"
                  )?.create === false
                    ? ".5"
                    : "1"
                }`,
              }}
              size="large"
              onClick={showModal}
            >
              Add User
            </Button>
          </div>
          <div className="mt-3">
            <Table
              columns={columns}
              dataSource={dataSource}
              loading={loading}
              pagination={{
                total: totalData,
                pageSize: tableParams.pageSize,
                showSizeChanger: true,
                showQuickJumper: true,
                pageSizeOptions: ["10", "20", "50"],
              }}
              onChange={handleTableChange}
            />
          </div>
        </div>
        <UserModal
          fromTitle={fromTitle}
          open={show}
          buttonLoading={buttonLoading}
          confirmLoading={confirmLoading}
          handleCancel={handleCancel}
          handleSubmit={handleSubmit}
          editFlag={editFlag}
          formButton={formButton}
          UserForm={UserForm}
          selectedModules={selectedModules}
          setSelectedModules={setSelectedModules}
          handleModuleChangenew={handleModuleChangenew}
          roles={rolesData}
          modules={modulesData}
          loading={loading}
        />
      </>
    );
}

export default User

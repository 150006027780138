import React, { useEffect } from "react";
import { Upload, message } from "antd";
import { InboxOutlined } from "@ant-design/icons";
import { errorPopup } from "../../../Services/toasterMessage";
import "./index.scss";
import actions from "../../../redux/ReconAndSettlement/actions";
import { useDispatch, useSelector } from "react-redux";

const DnD = ({ setFormDataState }) => {
  const { uploadXmlFileZip } = useSelector((state) => {
    return {
      uploadXmlFileZip: state?.reconReducer?.uploadXmlFileZip,
    };
  });
  const { uploadXmlZipFileNotified, uploadXmlZipFileReset } = actions;

  const uploadProps = {
    name: "file",
    multiple: false,
    beforeUpload(file) {
      // Allowed file types: XML and ZIP
      const allowedTypes = [
        "application/xml", // XML
        "text/xml", // XML
        "application/zip", // ZIP
        "application/x-zip-compressed", // ZIP (alternative MIME type)
      ];

      if (!allowedTypes.includes(file.type)) {
        message.error(`${file.name} is not a valid file type.`);
        return Upload.LIST_IGNORE;
      }

      const isSizeValid = file.size / 1024 / 1024 < 100; // Check if the file size is under 100MB
      if (!isSizeValid) {
        message.error(`${file.name} exceeds the size limit.`);
        return Upload.LIST_IGNORE;
      }

      // Convert file to a readable format for further processing
      const reader = new FileReader();
      reader.onload = (e) => {
        setFormDataState(file);
      };
      reader.readAsArrayBuffer(file); // Read ZIP files properly

      return false; // Prevent automatic upload
    },
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (uploadXmlFileZip?.response !== null) {
      setFormDataState(null);
    }
    setTimeout(() => {
      dispatch(uploadXmlZipFileNotified());
      dispatch(uploadXmlZipFileReset());
    }, 1000);
  }, [uploadXmlFileZip?.response, dispatch, setFormDataState]);

  return (
    <>
      <Upload.Dragger
        {...uploadProps}
        showUploadList={true}
        
        style={{ display: "flex", width: "75vw" }}
      >
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag a file to this area to upload
        </p>
        <p className="ant-upload-hint">
          Only XML (.xml) and ZIP (.zip) files are allowed. One file at a time.
        </p>
      </Upload.Dragger>
      <span className="text-success fs-5">{uploadXmlFileZip?.loading===true ? "Uploading selected file, please wait for a while..." : ""}</span>
    </>
  );
};

export default DnD;

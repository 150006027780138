import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DnD from "./DndXMLZipFile";
import { message } from "antd";
import { UXmlZipFilter } from "./UploadXMLZipFilter";
import actions from "../../../redux/ReconAndSettlement/actions";

const Uploads = () => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    cycleNo: "",
    billerId: null,
  });
  const [file, setFile] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const { uploadWithoutBillerId, uploadByBillerId, uploadXmlZipFileData } =
    useSelector((state) => {
      return {
        uploadWithoutBillerId:
          state.reconReducer?.uploadReconData?.withoutBillerId,
        uploadByBillerId: state.reconReducer?.uploadReconData?.byBillerId,
        uploadXmlZipFileData: state?.reconReducer?.uploadXmlFileZip,
      };
    });

  const form = new FormData();
  form.append("cycleNo", formData.cycleNo);
  if (file) {
    form.append("file", file);
  }
  const handleSubmit = () => {
    if (!file) {
      message.error("No file selected");
      return;
    }

    dispatch(
      actions.uploadXmlZipFile({
        payload: form,
      })
    );
  };

  // look for files and cycleno to disable the submit button
  useEffect(() => {
    if (file === null || formData.cycleNo === "") {
      setDisableSubmitButton(true);
    } else {
      setDisableSubmitButton(false);
    }
  }, [file, formData.cycleNo]);

  useEffect(() => {
    if (
      uploadXmlZipFileData?.loading === false &&
      uploadXmlZipFileData?.error !== null
    ) {
      messageApi.destroy();
      messageApi
        .open({
          type: "error",
          content:
            uploadXmlZipFileData?.error !== null && uploadXmlZipFileData?.error,
          duration: 1,
        })
        .then(() => dispatch(actions.uploadXmlZipFileReset()));
    } else if (
      uploadXmlZipFileData?.loading === false &&
      uploadXmlZipFileData?.error === null &&
      uploadXmlZipFileData?.notified === false &&
      uploadXmlZipFileData?.response?.url?.message?.length > 0
    ) {
      messageApi.destroy();
      messageApi
        .open({
          type: "success",
          content: uploadXmlZipFileData?.response?.url?.message,
          duration: 1,
        })
        .then(() => dispatch(actions.uploadXmlZipFileReset()));
    }
  }, [uploadXmlZipFileData]);

  return (
    <div>
      {contextHolder}
      <div className="filter-container">
        <UXmlZipFilter
          handleSubmit={handleSubmit}
          setFormData={setFormData}
          formData={formData}
          submitButton={disableSubmitButton}
        />
        <DnD setFormDataState={setFile} />
      </div>
    </div>
  );
};

export default Uploads;

"use client";
import React, { useState } from "react";
import { Tabs } from "antd";
import { useSelector } from "react-redux";
import Uploads from "./Upload";
import UploadXMLZipFile from "./UploadXmlZip";
import Filter from "./Filter";
import { FetchFileTable } from "./Table";

const DevelopmentTable = () => {
  const { newReport } = useSelector((state) => {
    return {
      newReport: state?.reconReducer?.uploadReconData?.newReport,
    };
  });
  const [selectedFile, setSelectedFile] = useState({
    cycleNo: 1,
    fileType: "JSON",
    date: new Date().toISOString(),
  });

  const { profileData } = useSelector((state) => {
    return {
      profileData: state.authReducer?.profileData,
    };
  });

  const items = [
    {
      key: "1",
      label: "Table",
      children: (
        <div className="">
          <Filter
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            loading={newReport?.loading}
          />
          <FetchFileTable />
        </div>
      ),
    },
    {
      key: "2",
      label: "Upload",
      children: (
        <div className="dev-upload">
          <Uploads
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            loading={newReport?.loading}
          />
        </div>
      ),
    },
    {
      key: "3",
      label: "Upload Xml Zip File",
      children: (
        <div className="dev-upload">
          <UploadXMLZipFile
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            loading={newReport?.loading}
          />
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="">
        {profileData?.internal === true ? (
          <Tabs
            style={{
              "--ant-tabs-tab-hover-color": "#2a901b",
              "--ant-tabs-tab-active-color": "#2a901b",
              "--ant-tabs-ink-bar-color": "#2a901b",
            }}
            defaultActiveKey="1"
            // If the user is an internal user, show both the table and upload tabs.
            // Otherwise, only show the table tab.
            items={
              profileData?.internal === true
                ? items
                : items.filter((item) => item.key === "1")
            }
            onChange={(key) => key}
            indicator={{
              size: (origin) => origin - 20,
              align: "center",
            }}
            // style={{
            //   padding: "4px",
            // }}
            type="line"
            animated
          />
        ) : (
          <div className="">
            <Filter
              selectedFile={selectedFile}
              setSelectedFile={setSelectedFile}
              loading={newReport?.loading}
            />
            <FetchFileTable />
          </div>
        )}
      </div>
    </>
  );
};

export default DevelopmentTable;

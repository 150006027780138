import React, { useState } from "react";
import {
  Modal,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  Checkbox,
  Collapse,
  message,
} from "antd";
import PropTypes from "prop-types";

const { Panel } = Collapse;

const UserModal = ({
  fromTitle,
  open,
  buttonLoading,
  handleCancel,
  handleSubmit,
  editFlag,
  formButton,
  UserForm,
  selectedModules,
  setSelectedModules,
  handleModuleChangenew,
  roles,
  modules,
}) => {
  const [permissions, setPermissions] = useState({});
  const [messageApi, contextHolder] = message.useMessage();

  // Show error message
  const showErrorMessage = (error) => {
    messageApi.error(error);
  };

  // Handle individual module permissions
  const handlePermissionChange = (module, selectedPermissions) => {
    if (
      selectedPermissions.length > 0 &&
      !selectedPermissions.includes("read")
    ) {
      selectedPermissions.push("read");
    }
    setPermissions((prev) => ({
      ...prev,
      [module]: selectedPermissions,
    }));
    UserForm.setFieldsValue({ [`${module}_permissions`]: selectedPermissions });
  };

  const onChangeRolePermission = (value) => {
    setSelectedModules([]);
    UserForm.setFieldsValue({ roleId: value });
    if (
      roles
        ?.filter((item) => item?.roleId === value)[0]
        ?.role?.includes("admin")
    ) {
      setPermissions((prev) => ({
        ...prev,
        users: ["read", "create", "update", "delete"],
        transactions: ["read", "create", "update", "delete"],
        "communication channel": ["read", "create", "update", "delete"],
        "settlement & reconciliation": ["read", "create", "update", "delete"],
        mails: ["read", "create", "update", "delete"],
      }));
      UserForm.setFieldsValue({
        permissions: [
          "users",
          "transactions",
          "communication channel",
          "settlement & reconciliation",
          "mails",
        ],
      });
      setSelectedModules([
        "users",
        "transactions",
        "communication channel",
        "settlement & reconciliation",
        "mails",
      ]);
    } else {
      setPermissions({});
      UserForm.setFieldsValue({ permissions: [] });
    }
  };

  return (
    <>
      {contextHolder}
      <Modal
        title={fromTitle}
        open={open}
        onCancel={() => {
          handleCancel();
          setSelectedModules([]);
        }}
        width={700}
        maskClosable={false}
        footer={[
          <Button key="back" onClick={handleCancel}>
            CANCEL
          </Button>,
          <Button
            type="primary"
            key="submit"
            style={{
              backgroundColor: "#073763",
              borderColor: "#073763",
              color: "white",
            }}
            form="userForm"
            loading={buttonLoading}
            htmlType="submit"
          >
            {formButton}
          </Button>,
        ]}
      >
        <Form
          id="userForm"
          form={UserForm}
          layout="vertical"
          onFinish={(values) => {
            try {
              handleSubmit(values);
            } catch (error) {
              showErrorMessage(error.message || "Something went wrong!");
            }
          }}
          autoComplete="off"
          initialValues={{
            roleId: UserForm.getFieldValue("roleId") || undefined,
          }}
        >
          <Row gutter={{ xs: 24, sm: 12, md: 24, lg: 24 }}>
            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="Name"
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Please enter user name!",
                  },
                  {
                    pattern: /^[a-zA-Z\s]{3,}$/,
                    message:
                      "Name must contain only alphabets and at least 3 characters.",
                  },
                ]}
              >
                <Input disabled={editFlag} placeholder="Enter user name" />
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="Roles"
                name="role"
                rules={[
                  {
                    required: true,
                    message: "Please select user role!",
                  },
                ]}
              >
                <Select
                  placeholder="Select user role"
                  allowClear
                  showSearch
                  onChange={(value) => onChangeRolePermission(value)}
                  filterOption={(input, option) =>
                    option.children.toLowerCase().includes(input.toLowerCase())
                  }
                >
                  {roles?.length > 0 &&
                    roles
                      ?.filter((role) => role.role !== "super admin")
                      ?.map((role) => (
                        <Select.Option key={role.role} value={role.roleId}>
                          {role.role}
                        </Select.Option>
                      ))}
                </Select>
              </Form.Item>
            </Col>

            <Col xs={24} sm={24} md={12} lg={12}>
              <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: "Please enter user email!",
                  },
                  {
                    type: "email",
                    message: "Please input a valid email!",
                  },
                ]}
              >
                <Input disabled={editFlag} placeholder="Enter user email" />
              </Form.Item>
            </Col>

            {!editFlag && (
              <Col xs={24} sm={24} md={12} lg={12}>
                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                    {
                      pattern:
                        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{12,}$/,
                      message:
                        "Password must have at least 12 characters, including uppercase, lowercase, number, and special character.",
                    },
                  ]}
                >
                  <Input.Password
                    autoComplete="new-password"
                    placeholder="Enter strong password"
                  />
                </Form.Item>
              </Col>
            )}

            <Col xs={24}>
              <Form.Item
                label="Permissions"
                name="permissions"
                rules={[
                  {
                    required: true,
                    message: "Please select permissions!",
                  },
                ]}
              >
                <Checkbox.Group>
                  <Row gutter={[16, 16]}>
                    {modules
                      ?.filter(
                        (item) =>
                          item && item?.moduleName !== "biller performance"
                      )
                      .map((module) => (
                        <Col key={module.moduleName} span={12}>
                          <Checkbox
                            disabled={
                              !UserForm?.getFieldValue("roleId") ||
                              roles
                                ?.filter(
                                  (item) =>
                                    item?.roleId ===
                                    UserForm.getFieldValue("roleId")
                                )[0]
                                ?.role?.includes("admin")
                            }
                            value={module.moduleName}
                            onChange={() =>
                              handleModuleChangenew(module.moduleName)
                            }
                          >
                            {module.moduleName}
                          </Checkbox>
                        </Col>
                      ))}
                  </Row>
                </Checkbox.Group>
              </Form.Item>

              {(permissions?.length > 0 || roles?.filter((item) => item?.roleId === UserForm.getFieldValue("roleId"))[0]?.role !== "master admin")&&<Collapse defaultActiveKey={selectedModules}>
                {selectedModules.map((module) => (
                  <Panel header={module} key={module}>
                    <Form.Item
                      name={`${module}_permissions`}
                      rules={[
                        {
                          required: true,
                          message: "Please select at least one option!",
                        },
                      ]}
                    >
                      <Checkbox.Group
                        value={permissions[module] || ["read"]}
                        onChange={(selected) =>
                          handlePermissionChange(module, selected)
                        }
                      >
                        <Checkbox value="create">Allow Create</Checkbox>
                        <Checkbox value="read" disabled>
                          Allow Read
                        </Checkbox>
                        <Checkbox value="update">Allow Update</Checkbox>
                        <Checkbox value="delete">Allow Delete</Checkbox>
                      </Checkbox.Group>
                    </Form.Item>
                  </Panel>
                ))}
              </Collapse>}
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

UserModal.propTypes = {
  fromTitle: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  buttonLoading: PropTypes.bool.isRequired,
  handleCancel: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  editFlag: PropTypes.bool.isRequired,
  formButton: PropTypes.string.isRequired,
  UserForm: PropTypes.object.isRequired,
  selectedModules: PropTypes.array.isRequired,
  handleModuleChangenew: PropTypes.func.isRequired,
  roles: PropTypes.array.isRequired,
  modules: PropTypes.array.isRequired,
};

export default UserModal;
